module.exports = {
  localBusiness: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Think Plus - Advertising",
    "image": "https://think-plus.gr/images/tp-logoblack.svg",
    "@id": "",
    "url": "http://think-plus.gr/",
    "telephone": "+302106101478",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Amarousiou Chalandriou 36B,",
      "addressLocality": "Marousi",
      "postalCode": "15125",
      "addressCountry": "Greece",
      "addressRegion": "+30"
    },
    "areaServed": "Greece",
    "priceRange": "€€€-€€€€€",
    "sameAs": [
      "https://www.facebook.com/thinkplusadvertising",
      "https://www.instagram.com/thinkplus_advertising/?hl=el",
      "https://twitter.com/ThinkPlusAdvert",
      "https://www.youtube.com/channel/UCRERWJ-RkKXd17KMz2vyH4Q",
      "https://www.linkedin.com/company/think-plus-advertising/about/"
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "10:00",
        "closes": "18:00"
      }
    ]
  },
  organization: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Think Plus - Advertising",
    "alternateName": "Think Plus Advertising",
    "url": "https://think-plus.gr",
    "logo": "https://think-plus.gr/images/tp-logoblack.svg",
    "areaServed": "Greece",
    "award": [
      "Χρυσό Βραβείο στα E-volution Awards για το e-socks.gr (Πελατοκεντρικότητα & Διαδικασίες - Προσωποποιημένες Υπηρεσίες)",
      "Silver Βραβείο στα E-volution Awards για το Skroutz Application (Υπηρεσίες & Υποδομές Ηλεκτρονικού Επιχειρείν - Υπηρεσίες Υποδομών)",
      "Bronze Βραβείο στα E-volution Awards για την MEL (Ψηφιακή Επικοινωνία & Marketing - Best Cross Border Marketing)",
      "Χρυσό Βραβείο στα Franchise Awards 2018 (Best use of Technology - «Smileworks»)",
      "Χρυσό Βραβείο στα Franchise Awards 2018 (Most Innovative Concept - «Smileworks»)",
      "Δεύτερο Βραβείο στα Mobile Excellence Awards 2018 στις καλύτερες Mobile Εφαρμογές - «Fitgate»",
      "Πρώτο Διεθνές βραβείο καλύτερης Facebook Ενέργειας για τις χρονιές 2012, 2013 & 2014 (SPI Group)",
      "Χρυσό Βραβείο στα Social Media Awards 2016 (Best use of social media in Real Life)",
      "Χρυσό Βραβείο στα Social Media Awards 2015 (Best use of social media in Real Life)",
      "Silver Βραβείο στα Social Media Awards 2016 «Innovation in Social Media»",
      "Δύο Βραβεία στα Social Media Awards 2013 (Jägermeister & Stolichnaya)",
      "Δεύτερο βραβείο στην ελληνική «Εφορμογιάδα» για την mobile εφαρμογή «FaceMetrics» υπό την αιγίδα της Εθνικής Τράπεζας"
    ],
    "sameAs": [
      "https://www.facebook.com/thinkplusadvertising",
      "https://twitter.com/ThinkPlusAdvert",
      "https://www.instagram.com/thinkplus_advertising/?hl=el",
      "https://www.youtube.com/channel/UCRERWJ-RkKXd17KMz2vyH4Q",
      "https://www.linkedin.com/company/think-plus-advertising/about/"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "2106101478",
      "contactType": "customer service",
      "contactOption": "TollFree",
      "areaServed": "GR",
      "availableLanguage": "Greek"
    }
  }
}