import React from 'react'

import '../../styles/components/our-services.scss'
import { Link } from 'gatsby';

class OurServices extends React.Component {
  render() {
    let articleClass = this.props.decoration ? 'services dec-section--line-vertical' : 'services';

    return (
      <section className={articleClass}>
        <div className="container medium">
          <div className="row">
            <div className="col">
              <div className="grid-container--three services__container">
                <Link to="/kataskeyi-istoselidon/" className="services__box link--plain">
                  <svg viewBox="0 0 123 89" xmlns="http://www.w3.org/2000/svg"><g stroke="#313131" strokeWidth="3" fill="none" fillRule="evenodd"><path d="M121 27.112A3.118 3.118 0 00117.875 24h-30.75A3.118 3.118 0 0084 27.112v55.776A3.118 3.118 0 0087.125 86h30.75A3.118 3.118 0 00121 82.888V27.112zM84 39h37M96 30h12M84 76h37" /><path d="M75.69 71H4.508C3.123 71 2 69.848 2 68.426V4.575C2 3.152 3.123 2 4.508 2h102.983C108.877 2 110 3.152 110 4.575v12.051M2 55h73M28 69v19M19 87h57" /><path d="M14 16h62M14 30h62M14 46h62M88 51h28M88 64h28" strokeDasharray="8.984999999999999,8.984999999999999" /></g></svg>
                  <hr className="hr--theme" />
                  <h3>Web Design &amp; Development</h3>
                  {
                    this.props.subtitle &&
                    <p>Εμείς στη Think Plus σχεδιάζουμε με γνώμονα τη μοναδικότητα, τις απαιτήσεις και το επίπεδο της επιχείρησής σας.</p>
                  }
                </Link>
                <Link to="/design-art/" className="services__box link--plain">

                  <svg viewBox="0 0 98 90" xmlns="http://www.w3.org/2000/svg"><defs><path id="a" d="M0 90h98V0H0z" /></defs><g fill="none" fillRule="evenodd"><path d="M45 54a4 4 0 108 0 4 4 0 00-8 0zM63 90V72.657a.657.657 0 00-.656-.657H34.656a.657.657 0 00-.656.657V90M50 23h4.211S57.177 35.463 65 44.315L58.85 72M47 23h-4.218S39.817 35.463 32 44.315L37.644 72" stroke="#313131" strokeWidth="3" /><mask id="b" fill="#fff"><use href="#a" /></mask><path stroke="#313131" strokeWidth="3" mask="url(#b)" d="M44 11h10V1H44zM11 40h10V30H11z" /><path d="M17 31C17 17.745 27.82 7 41.167 7H45M76 40h10V30H76z" stroke="#313131" strokeWidth="3" mask="url(#b)" /><path d="M81 31C81 17.745 70.18 7 56.833 7H53M55 7h34M9 7h34M97 7a4 4 0 10-8 0 4 4 0 008 0zM9 7a4 4 0 10-8 0 4 4 0 008 0zM48 24v26" stroke="#313131" strokeWidth="3" mask="url(#b)" /></g></svg>
                  <hr className="hr--theme" />
                  <h3>Design &amp; Art</h3>
                  {
                    this.props.subtitle &&
                    <p>Επιβλητικό design, πρωτότυπο copywriting και δυνατό SEO αποτελούν τα κύρια χαρακτηριστικά του νέου σας site με την Think Plus.</p>
                  }

                </Link>
                <Link to="/video-photography/" className="services__box link--plain">
                  <svg viewBox="0 0 101 91" xmlns="http://www.w3.org/2000/svg"><g stroke="#313131" strokeWidth="3" fill="none" fillRule="evenodd"><path d="M71.499 79H7.502A.507.507 0 017 78.489V40.51c0-.282.225-.511.502-.511h63.997c.277 0 .501.229.501.51V78.49a.506.506 0 01-.501.511z" /><path d="M61.478 63H34.522a.514.514 0 01-.522-.506V47.507c0-.28.233-.507.522-.507h26.956c.289 0 .522.227.522.507v14.987c0 .28-.233.506-.522.506zM65 79v9.519c0 .266-.23.481-.513.481H16.513c-.283 0-.513-.215-.513-.481V79M41 21.5C41 32.27 32.27 41 21.5 41S2 32.27 2 21.5 10.73 2 21.5 2 41 10.73 41 21.5zM84 21.5C84 32.27 75.27 41 64.5 41S45 32.27 45 21.5 53.73 2 64.5 2 84 10.73 84 21.5z" /><path d="M63 8c3.314 0 6.314 1.343 8.485 3.515A11.962 11.962 0 0175 20M20 8c3.314 0 6.314 1.343 8.485 3.515A11.962 11.962 0 0132 20M98.69 74H85.31a.313.313 0 01-.309-.317V44.317c0-.175.138-.317.309-.317H98.69c.17 0 .309.142.309.317v29.366a.313.313 0 01-.31.317zM83 65h-9M74 53h9M89 50h7M89 59h7M89 68h7M30 72h18M25 72h-8" /></g></svg>
                  <hr className="hr--theme" />
                  <h3> Video Production</h3>
                  {
                    this.props.subtitle &&
                    <p>Εγγυόμαστε την δημιουργία βίντεο παραγωγών υψηλής ποιότητας ώστε να αποκτήσετε την πιο δυνατή διαφημιστική προβολή.</p>
                  }
                </Link>
                <Link to="/online-marketing/" className="services__box link--plain">
                  <svg viewBox="0 0 87 100" xmlns="http://www.w3.org/2000/svg"><g stroke="#313131" strokeWidth="3" fill="none" fillRule="evenodd"><path d="M43.5 8h0C27.686 8 14.866 20.804 14.866 36.6V52.08c0 5.734-4.653 10.381-10.394 10.381H2.515a.514.514 0 00-.515.515v20.509c0 .284.23.514.515.514h81.97c.285 0 .515-.23.515-.514v-20.51a.514.514 0 00-.515-.514h-1.957c-5.74 0-10.393-4.647-10.393-10.38v-9.277M58 84c0 7.732-6.044 14-13.5 14S31 91.732 31 84" /><path d="M85 20c0 9.94-7.835 18-17.5 18S50 29.94 50 20c0-9.941 7.835-18 17.5-18S85 10.059 85 20zM55 21h5M65 21h5M74 21h5" /></g></svg>
                  <hr className="hr--theme" />
                  <h3>Online Marketing</h3>
                  {
                    this.props.subtitle &&
                    <p>Στη Think Plus επιλέγουμε τα κατάλληλα social media για εσάς, που θα αυξήσουν την αλληλεπίδραση σας με το κοινό.</p>
                  }
                </Link>
                <Link to="/above-the-line/" className="services__box link--plain">
                  <svg viewBox="0 0 99 94" xmlns="http://www.w3.org/2000/svg"><g stroke="#313131" strokeWidth="3" fill="none" fillRule="evenodd"><path d="M75 69.6c0 5.191-4.298 9.4-9.6 9.4H20.6c-5.302 0-9.6-4.209-9.6-9.4V41.4c0-5.191 4.298-9.4 9.6-9.4h44.8c5.302 0 9.6 4.209 9.6 9.4v28.2zM89 39a4 4 0 11-8 0 4 4 0 018 0z" /><path d="M88.477 90H10.523C5.836 90 2 86.131 2 81.401V32.598C2 27.869 5.836 24 10.523 24h77.954C93.164 24 97 27.869 97 32.598V81.4C97 86.131 93.164 90 88.477 90zM12 94v-5M90 94v-5" /><path d="M86 75V49" strokeDasharray="5,5,5,5,5,5" /><path d="M41 24s1.065-8 8.078-8S57 23.508 57 24M55 17L71 1M44 17L28 1" /></g></svg>
                  <hr className="hr--theme" />
                  <h3>Above the Line</h3>
                  {
                    this.props.subtitle &&
                    <p>Γνωρίζουμε πώς να φτιάχνουμε πιασάρικα διαφημιστικά σποτ που επικοινωνούν το προϊόν σας.</p>
                  }
                </Link>
                <Link to="/below-the-line/" className="services__box link--plain">
                  <svg viewBox="0 0 95 93" xmlns="http://www.w3.org/2000/svg"><g stroke="#313131" strokeWidth="3" fill="none" fillRule="evenodd"><path d="M74.957 93h15.558a.485.485 0 00.485-.486V39.52c0-4.683-3.792-8.48-8.47-8.48V2H72.47v29.04c-4.678 0-8.47 3.797-8.47 8.48M68 9h19M68 16h19" /><path d="M91 52H77v26h14M83 60h7M83 65h7M83 71h7M48.645 92H2.355A.356.356 0 012 91.644V52.356c0-.197.159-.356.355-.356h46.29c.196 0 .355.16.355.356v39.288a.356.356 0 01-.355.356zM2 64h47M8 70h4M15 70h4M24 70h4M32 70h4M40 70h4M8 78h4M15 78h4M24 78h4M32 78h4M40 78h4M8 87h4M15 87h4M24 87h4M32 87h4M40 87h4M11 47v11M21 47v11M31 47v11M41 47v11M63 71c-4.419 0-8-3.44-8-7.683V44h16v19.317C71 67.56 67.419 71 63 71zM64 71v22M54 93h19M31 8v7M39 10l-4 7M46 16l-7 4M47 24.027L40 24M46 33l-7-4M39 39l-4-7M31 41l.053-7M22 39l4-7M16 33l7-4M14 24l7 .08M16 16l7 4M23 10l4 7" /></g></svg>
                  <hr className="hr--theme" />
                  <h3>Below the Line</h3>
                  {
                    this.props.subtitle &&
                    <p>Επιλέξτε την Think Plus για το επερχόμενο event σας και δώστε νέα ώθηση στην εταιρική σας παρουσία.</p>
                  }
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OurServices